import '../../css/Wallpaper.css';
import React from 'react';
import Dimensions from 'react-dimensions';

class Wallpaper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth : window.innerWidth,
      windowHeight : window.innerHeight +1
    }
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight +1 });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }


  render (){
    return (
      <div style={{height:this.state.windowHeight , width:this.state.windowWidth}} className='wallpaper-wrapper'>
        <div className='imgWrapper'>
        </div>
      </div>
    );
  }
}

export default Wallpaper;
