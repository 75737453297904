import '../../css/AboutListWrapper.css';
import FirstElement from './FirstElement.js';
import SecondElement from './SecondElement.js';
import ThirdElement from './ThirdElement.js';
import FourthElement from './FourthElement.js';
import {connect} from 'react-redux';

import React from 'react';

class AboutListWrapper extends React.Component {
  render(){
    const { testParam } = this.props;
    return (
        <div className= {testParam === 'homeScrollButtonClicked' ? 'About-list-wrapper' : 'About-list-wrapper-before'}>
          <div className='About-First-two-elements'>
            <FirstElement/>
            <SecondElement/>
          </div>
          <div className='About-Second-two-elements'>
            <ThirdElement/>
            <FourthElement/>
          </div>

        </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}
export default connect(mapStateToProps)(AboutListWrapper);
