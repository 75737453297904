export const TEST_ACTION = 'TEST_ACTION';
export const SCROLLEDSTATE_ACTION = 'SCROLLEDSTATE_ACTION';
export const SHOWPROFILEANDSKILL_ACTION = 'SHOWPROFILEANDSKILL_ACTION';
export const LOADARROWS_ACTION = 'LOADARROWS_ACTION';
export const LOADSOMETHINGABOUTME_ACTION = 'LOADSOMETHINGABOUTME_ACTION';
export const SOMETHINGABOUTMEYOFFSET_ACTION = 'SOMETHINGABOUTMEYOFFSET_ACTION';
export const PICTUREANDSKILLYOFFSET_ACTION = 'PICTUREANDSKILLYOFFSET_ACTION';
export const SHOWSOMETHINGABOUTME_ACTION = 'SHOWSOMETHINGABOUTME_ACTION';
export const SHOWPORTFOLIO_ACTION = 'SHOWPORTFOLIO_ACTION';
export const PORTFOLIOYOFFSET_ACTION = 'PORTFOLIOYOFFSET_ACTION';
export const CONTACTYOFFSET_ACTION = 'CONTACTYOFFSET_ACTION';
