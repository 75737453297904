import '../../css/Navigation.css';
import React from 'react';
import {connect} from 'react-redux';
import djlogo from '../../img/djlogo.png';


class Navigation extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     aboutUsRibbonMove : false,
     galleryRibbonMove : false,
     contactRibbonMove : false,
     logoMounted: false,
     cssWrapperMounted: false,
     scrolledBelow: false,
     windowWidth : window.innerWidth,
     windowHeight : window.innerHeight,
     burgerClicked: false
   };
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    setTimeout(()=>{
      this.setState({
        logoMounted: true
      })
    },100)
    setTimeout(()=>{
      this.setState({
        cssWrapperMounted: true
      })
    },1000)
  }
  mouseEnter( stateValue ) {
    this.setState({ [stateValue]: true });
  }
  mouseLeave( stateValue ) {
    this.setState({ [stateValue]: false });
  }
  changeScrollState (value){
    this.setState({
      scrolledBelow: value
    })
  }
  render() {
    const { testParam , scrolledStateParam , portfolioYOffsetParam , contactYOffsetParam } = this.props;
    return (

      <div className= {
        this.state.windowWidth > 1000 ?
        (scrolledStateParam === 'about' ? 'navigation-wrapper blue' :
        scrolledStateParam === 'portfolio' ? 'navigation-wrapper yellow' : 'navigation-wrapper') :
        'navigation-wrapper-unfixed'
      }>
        { this.state.windowWidth > 1000 ?
        <div className={
          scrolledStateParam === 'about' ? 'css-wrapper-scrolled-to-about' :
          scrolledStateParam === 'portfolio' ? 'css-wrapper-scrolled-to-portfolio' :
          this.state.logoMounted ? 'css-wrapper' :
          'css-wrapper-before'
        }>
          <div className='inner-wrapper'>
            <div
            onClick={()=> {
              window.scrollTo({top: 0,behavior: 'smooth'});
            }}
            className={scrolledStateParam !== 'portfolio' ? 'home-wrapper' : 'home-wrapper-negative'}>
              <p> Home </p>
            </div>
            <div
            onClick={()=> {
              window.scrollTo({top: window.innerHeight,behavior: 'smooth'});
            }}
            className={scrolledStateParam !== 'portfolio' ? 'about-wrapper' : 'about-wrapper-negative'}>
              <p> About </p>
              <div className= 'about-ribbon'/>
            </div>
            <div className={this.state.logoMounted === true ? 'logo-wrapper' : 'logo-wrapper-before'}>
            </div>
            <div
            onClick={()=> {
              window.scrollTo({top: portfolioYOffsetParam,behavior: 'smooth'});
            }}
            className={scrolledStateParam !== 'portfolio' ? 'gallery-wrapper' : 'gallery-wrapper-negative'}>
              <p> Portfolio </p>
            </div>
            <div
            onClick={()=> {
              window.scrollTo({top: contactYOffsetParam,behavior: 'smooth'});
            }}
            className={scrolledStateParam !== 'portfolio' ? 'contact-wrapper' : 'contact-wrapper-negative'}>
              <p> Contact </p>
            </div>
          </div>
          <div className={
            this.state.cssWrapperMounted && scrolledStateParam !== 'portfolio' ? 'navigation-line-after' :
            this.state.cssWrapperMounted && scrolledStateParam === 'portfolio' ? 'navigation-line-negative':
            'navigation-line-before'}
          />
        </div>
        :
        <div className='burger-wrapper'>
          <button
            onClick={()=> {
              this.setState({burgerClicked:!this.state.burgerClicked});
            }}
            className={this.state.burgerClicked ? 'hamburger hamburger--spin is-active' : 'hamburger'}  type="button">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
          </button>
          <div className='burger-side-menu'
          style={{
            height:this.state.windowHeight,
            width:this.state.windowWidth,
            top:this.state.burgerClicked ? 0 : -this.state.windowHeight - 10
          }}
          >
            <button
            onClick={()=> {
              this.setState({burgerClicked:!this.state.burgerClicked});
            }}
            style={{background:'transparent' , border:'none', marginTop:'15%' , width:'100%' , height:'20%'}}>
              <img src={djlogo} style={{ width:this.state.windowWidth/5 , height:this.state.windowWidth/5 }} alt='djole'/>
            </button>
            <div style={{width:'100%' , height:'60%'}}>
              <div style={{with:'100%' , height:'80%' , marginTop:'20%'}}>
                <div
                onClick={()=> {
                  window.scrollTo({top: 0 ,behavior: 'smooth'});
                  this.setState({burgerClicked:!this.state.burgerClicked});
                }}
                className='side-menu-list'>
                  Home
                </div>
                <div
                onClick={()=> {
                  window.scrollTo({top: window.innerHeight,behavior: 'smooth'});
                  this.setState({burgerClicked:!this.state.burgerClicked});
                }}
                className='side-menu-list'>
                  About
                </div>
                <div
                onClick={()=> {
                  window.scrollTo({top: portfolioYOffsetParam,behavior: 'smooth'});
                  this.setState({burgerClicked:!this.state.burgerClicked});
                }}
                className='side-menu-list'>
                  Portfolio
                </div>
                <div
                onClick={()=> {
                  window.scrollTo({top: contactYOffsetParam,behavior: 'smooth'});
                  this.setState({burgerClicked:!this.state.burgerClicked});
                }}
                className='side-menu-list'>
                  Contact
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  const scrolledStateParam = state.scrolledStateParam;
  const portfolioYOffsetParam = state.portfolioYOffsetParam;
  const contactYOffsetParam = state.contactYOffsetParam;
  return {
    scrolledStateParam,
    portfolioYOffsetParam,
    contactYOffsetParam
  }
}
export default connect(mapStateToProps)(Navigation);
