import './App.css';
import Home from './components/home/Home.js';
import About from './components/about/About.js';
import Portfolio from './components/portfolio/Portfolio.js';
import Contact from './components/contact/Contact.js';
import { testAction , scrolledStateAction , showProfileAndSkillAction , loadArrowsAction , showSomethingAboutMeAction , showPortfolioAction } from './actions/main';
import {connect} from 'react-redux';
import React from 'react';

class App extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight
   }
 }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
 }
 updateDimensions = () => {
   this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
 }
 componentWillUnmount() {
   window.removeEventListener('resize', this.updateDimensions);
 }
  render(){
    const { pictureAndSkillYOffsetParam , showSomethingAboutMeParam , somethingAboutMeYOffsetParam ,portfolioYOffsetParam } = this.props;
    window.onscroll = () => {
      if(window.scrollY > (pictureAndSkillYOffsetParam - window.innerHeight/1.5)) {
        this.props.dispatch(showProfileAndSkillAction(true));
        setTimeout(()=> {this.props.dispatch(loadArrowsAction(true))} , 1000)
      }
      if(window.scrollY > (somethingAboutMeYOffsetParam - window.innerHeight/1.5)) {
        this.props.dispatch(showSomethingAboutMeAction(true));
      }
      if(window.scrollY > portfolioYOffsetParam - 300) {
        this.props.dispatch(showPortfolioAction(true));
      }
      if((window.scrollY) < window.innerHeight){
        this.props.dispatch(scrolledStateAction('home'));
      }
      else if(window.scrollY >= window.innerHeight && window.scrollY < portfolioYOffsetParam - 50) {
        this.props.dispatch(scrolledStateAction('about'));
      }
      else if(window.scrollY >= portfolioYOffsetParam - 50) {
        this.props.dispatch(scrolledStateAction('portfolio'));
      }
      if (window.scrollY >= window.innerHeight/1.5) {
        this.props.dispatch(testAction('homeScrollButtonClicked'));
      }
    }
    return (
      <div className="App">
        <Home/>
        <About/>
        <Portfolio/>
        <Contact/>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const somethingAboutMeYOffsetParam = state.somethingAboutMeYOffsetParam;
  const pictureAndSkillYOffsetParam = state.pictureAndSkillYOffsetParam;
  const showSomethingAboutMeParam = state.showSomethingAboutMeParam;
  const portfolioYOffsetParam = state.portfolioYOffsetParam;
  return {
    somethingAboutMeYOffsetParam,
    pictureAndSkillYOffsetParam,
    showSomethingAboutMeParam,
    portfolioYOffsetParam
  }
}
export default connect(mapStateToProps)(App);
