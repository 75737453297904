import '../../css/AboutProfileSkillWrapper.css';
import {connect} from 'react-redux';
import React from 'react';

class LeftSideSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skillsData: [
        {
          name: 'Node.js',
          level: '80%'
        },
        {
          name: 'HTML',
          level: '95%'
        },
        {
          name: 'CSS',
          level: '95%'
        },
        {
          name: 'React.js',
          level: '80%'
        },
        {
          name:'ReactNative',
          level:'75%'
        }
      ]
    };
  }
  render(){
    const { testParam , loadArrowsParam , showProfileAndSkillParam } = this.props;
    return (
      <div className='left-side-wrapper'>
        {
          this.state.skillsData.map((object , index) =>{
            return(
              <div key={index} className='left-side-skill-react-js' style={{ marginBottom: object.name !== 'Javascript' || object.name !== 'React.js'  ? '37.5px' : null}} >
                <div style={{marginLeft:showProfileAndSkillParam ? 0 : -window.innerWidth/2}} className='left-side-inner-wrapper'>
                  <div className='left-arrow-tale'>
                    {object.name}
                  </div>
                  <div className='left-body-wrapper'>
                    <div className={testParam === 'homeScrollButtonClicked' ? 'left-fill-skill-level' : null} style={{width: loadArrowsParam ? object.level : 0}}/>
                  </div>
                  <div className='left-triangle-wrapper'/>
                  <div className='left-percentage'>
                    {object.level}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  const loadArrowsParam = state.loadArrowsParam;
  const showProfileAndSkillParam = state.showProfileAndSkillParam;
  return {
    testParam,
    loadArrowsParam,
    showProfileAndSkillParam
  }
}

export default connect(mapStateToProps)(LeftSideSkill);
