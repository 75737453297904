import {connect} from 'react-redux';
import React from 'react';
import '../../css/portfolioPartnerLogos.css';
import beoguma from '../../video/beoguma.mp4';
import cleanCommerce from '../../video/cleanCommerce.mp4';
import { portfolioYOffsetAction } from '../../actions/main';
import beogumaLogo from '../../img/beogumaLogo.png';
import cleanCommerceLogo from '../../img/cleanCommerceLogo.png';
import zepterLogo from '../../img/zepterLogo.png';
import galenikaLogo from '../../img/galenikaLogo.png';
import optiplazaLogo from '../../img/optiplazaLogo.png';
import bitcoinLogo from '../../img/bitcoinLogo.png';



class PortfolioPartnerLogos extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      logos: [beogumaLogo , cleanCommerceLogo , zepterLogo , galenikaLogo , optiplazaLogo ]
    };
  }

  render(){
    return (
      <div style={{width:'100%' , height:'100%' , textAlign:'center' , marginBottom:'5%'}}>
      <div className='portfolio-partner-logo-header'>
        I am proud to have collaborated with some awesome companies:
      </div>
        {
          this.state.logos.map(( a , index ) => {
            console.log(a ,'aaa')
            return(
              <div key={index} style={{  marginLeft:'4%' , marginRight:'4%' , marginBottom:'4%' , justifyContent:'center' , display:'inline-block',position:'relative'}}>
                <img src={a} style={{ width:200 , height:150 }} alt="fireSpot"/>
              </div>
            )
          })
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam,
  }
}
export default connect(mapStateToProps)(PortfolioPartnerLogos);
