import {connect} from 'react-redux';
import React from 'react';
import '../../css/Portfolio.css';
import beoguma from '../../video/beoguma.mp4';
import cleanCommerce from '../../video/cleanCommerce.mp4';
import rigWatcher from '../../video/rigWatcher.mp4';
import onlineDoctor from '../../video/onlineDoctor.mp4';
import { portfolioYOffsetAction } from '../../actions/main';

const looping = [beoguma , cleanCommerce , rigWatcher , onlineDoctor];
const videos =['beoguma' , 'cleanCommerce' , 'rigWatcher' , 'onlineDoctor']
const videoDescrition =[
  'Car service app for checking basically everything that you fixed or serviced on your vehicle...',
  'Private app for scheduling deep washing services for furniture, carpets or cars...',
  'Crypto mining app for checking stats of pool,getting notifications for changes...',
  'health based app for checking biochemical, hormonal, urogical and other results...']

class PortfolioVideoGallery extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      videoHovered: [false , false, false , false],
      removeHoveredWrapper: [false , false , false , false]
    };
  }
  turnOffOnMouseOut() {
    setTimeout(()=>{
      this.setState({videoHovered:[false,false,false,false]});
    },10)
  }
  render(){
    return (
      <div style={{width:'100%' , height:'100%' , textAlign:'center' , marginBottom:'5%'}}>
        {
          looping.map(( a , index ) => {
            return(
              <div key={a} style={{  boxShadow: '0px 0px 10px 3px rgba(13, 19, 33,0.7)',border: '5px solid #e8c351',width:'234px' , height:'458px' , margin:'2%' , justifyContent:'center' , display:'inline-block',position:'relative'}}>
                <video
                style={{objectFit: 'cover'}}
                onMouseOver={event => event.target.play()}
                onMouseOut={(event) => {
                  this.setState({removeHoveredWrapper:[false,false,false,false]});
                  this.turnOffOnMouseOut();
                  event.target.pause();
                }}
                width="100%"
                height="100%"
                src={a}
                type="video/mp4"
                muted="muted">
                </video>
                <div
                onMouseOver={()=>{
                  let ids = [...this.state.videoHovered];
                  ids[index] = true;
                  console.log(...this.state.videoHovered , 'video hovered');
                  this.setState({ videoHovered: ids });
                  setTimeout(()=> {this.setState({ removeHoveredWrapper: ids })} , 200);
                }}
                style={{
                  opacity: this.state.videoHovered[index] ? 0 : 1 ,
                  transitionDuration:'1s' ,
                  display: this.state.removeHoveredWrapper[index] ? 'none' : 'block'
                }}
                className='videoTextContent'>
                  <div className='videoTextInnerWrapper'>
                    <div className='videoTextNameWrapper'>
                      {videos[index]}
                    </div>
                    <div className='videoTextDescriptionWrapper'>
                      {videoDescrition[index]}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam,
  }
}
export default connect(mapStateToProps)(PortfolioVideoGallery);
