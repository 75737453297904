import '../../css/AboutFourthElement.css';
import {connect} from 'react-redux';

import React from 'react';

class FourthElement extends React.Component {
  constructor(props) {
   super(props);
   this.state = {

   };
 }
  render(){
    const { testParam } = this.props;
    return (
      <div className="fourth-box">
        <div className={testParam ==='homeScrollButtonClicked' ? 'fourth-box-inner-mounted fourth-box-inner ' : 'fourth-box-inner'}>
          <div className="fourth-box-front">
          </div>
          <div className="fourth-box-back">
          </div>
        </div>
        <div className='fourth-box-text'>
          <div className='fourth-box-text-headline'>
            Complex
          </div>
          <div className='fourth-box-text-content'>
            Dynamic components thats accelerates app and shortens code. 
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}
export default connect(mapStateToProps)(FourthElement);
