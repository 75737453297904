import '../../css/AboutThirdElement.css';
import {connect} from 'react-redux';

import React from 'react';

class ThirdElement extends React.Component {
  constructor(props) {
   super(props);
   this.state = {

   };
 }
  render(){
    const { testParam } = this.props;
    return (
      <div className="third-box">
        <div className={testParam ==='homeScrollButtonClicked' ? 'third-box-inner-mounted third-box-inner ' : 'third-box-inner'}>
          <div className="third-box-front">
          </div>
          <div className="third-box-back">
          </div>
        </div>
        <div className='third-box-text'>
          <div className='third-box-text-headline'>
            Animated
          </div>
          <div className='third-box-text-content'>
            Dynamic and fluid animations no matter if it is a website or mobile app.
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}
export default connect(mapStateToProps)(ThirdElement);
