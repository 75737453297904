import {connect} from 'react-redux';
import React from 'react';
import '../../css/Contact.css';
import emailjs from 'emailjs-com';
import { contactYOffsetAction } from '../../actions/main';


class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      name: '',
      email: '',
      message:''
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.dispatch(contactYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
  }
  updateDimensions = () => {
    this.props.dispatch(contactYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  sendMail =(e)=> {
    e.preventDefault();
    emailjs.sendForm('service_p643jrc', 'template_cir1l6o', e.target, 'user_BVlzWlWyKbyXvQIXWLlgv')
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
    e.target.reset();
  }
  handleChange(e) {
    this.setState({ [e.target.name]:e.target.value })
  }

  render(){
    const { testParam } = this.props;
    return (
      <div className='contact-page-outer-wrapper' ref={this.wrapper}>
        <div style={{width:'100%' , height:'120px'}}>
          <svg viewBox="0 0 1000 20" preserveAspectRatio="none" width="100%" height="100%">
            <polygon points="0,0 1000,0 500,20"
             style={{fill:'#fae66e'}}/>
           </svg>
        </div>

        <div className='contact-page-inner-wrapper'>
          <div className='contact-page-header'>
            Contact
          </div>
          <div className='contact-page-message'>
            Wanna work together ? Feel free to send me a message !
          </div>
          <div className='contact-page-email-wrapper'>
            <form onSubmit={this.sendMail}>
              <input onChange={(e) => {this.handleChange(e)}} type="text" id="fname" name="name" placeholder="Your name.."/>
              <input onChange={(e) => {this.handleChange(e)}} type="email" id="lname" name="email" placeholder="Your gmail address.."/>
              <textarea onChange={(e) => {this.handleChange(e)}} type="text" id="message" name="message" placeholder="Message.."/>
              <input type="submit" value="Submit"/>
            </form>
          </div>
        </div>
      </div>

    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}
export default connect(mapStateToProps)(Contact);
