import '../../css/ProfilePicture.css';
import AboutListWrapper from './AboutListWrapper.js';
import {connect} from 'react-redux';
import React from 'react';

class ProfilePicture extends React.Component {
  render(){
    const { testParam } = this.props;
    return (
      <div className='Profile-picture'/>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}

export default connect(mapStateToProps)(ProfilePicture);
