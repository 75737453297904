import '../../css/HelloMessage.css';
import React from 'react';
import Dimensions from 'react-dimensions';
import HomePageButton from './HomePageButton.js';


class HelloMessage extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,
     changeMessageClass: false,
     fullLetter : 'My name is Nikola Djordjevic \n i am React.js & React-native developer',
     renderingLetters: ''
   };
 }
 componentDidMount() {
   setTimeout(()=> {
     this.setState({ changeMessageClass: true });
   } , 100);
   setTimeout(()=> {
     for(let x=0 ; x<this.state.fullLetter.length; x++){
       this.renderLetters(x);
     }
   } , 1000);
 }

 renderLetters ( value ){
   setTimeout(()=>{
     this.setState({
       renderingLetters : this.state.renderingLetters + this.state.fullLetter[value]
     })
   },50 * value);
 }
  render (){
    return (
      <div className={this.state.changeMessageClass=== false ? 'Message-wrapper' : 'Message-wrapper-after'}>
        <p className='Hello'>Hello there!</p>
        <p className='Message'>{this.state.renderingLetters}</p>
        <HomePageButton/>
      </div>
    );
  }
}

export default HelloMessage;
