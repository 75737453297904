import '../../css/HomePageButton.css';
import React from 'react';
import { testAction } from '../../actions/main';
import {connect} from 'react-redux';


class HomePageButton extends React.Component {
  render(){
    return (
      <div className="MessageDivWrapper">
      <button onClick={()=> {
        window.scrollTo({top: window.innerHeight,behavior: 'smooth'});
        setTimeout(()=>{
          this.props.dispatch(testAction('homeScrollButtonClicked'));
        },200)
      }}
      className="Home-button">
      <div className="text">Check out my work<br/><i className="arrow down"></i></div>
        <div className="svg-wrapper">
          <svg height="60" width="320" xmlns="http://www.w3.org/2000/svg">
            <rect className="shape" height="60" width="320" />
          </svg>
        </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}

export default connect(mapStateToProps)(HomePageButton);
