import '../../css/AboutSecondElement.css';
import {connect} from 'react-redux';

import React from 'react';

class SecondElement extends React.Component {
  constructor(props) {
   super(props);
   this.state = {

   };
 }
  render(){
    const { testParam } = this.props;
    return (
      <div className="second-box">
        <div className={testParam ==='homeScrollButtonClicked' ? 'second-box-inner-mounted second-box-inner ' : 'second-box-inner'}>
          <div className="second-box-front">
          </div>
          <div className="second-box-back">
          </div>
        </div>
        <div className='second-box-text'>
          <div className='first-box-text-headline'>
            Responsive
          </div>
          <div className='first-box-text-content'>
            My layouts will work on any device, big or small.
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}
export default connect(mapStateToProps)(SecondElement);
