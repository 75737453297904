import '../../css/AboutFirstElement.css';
import {connect} from 'react-redux';

import React from 'react';

class FirstElement extends React.Component {
  constructor(props) {
   super(props);
   this.state = {

   };
 }
  render(){
    const { testParam } = this.props;
    return (
      <div className="first-box">
        <div className={testParam ==='homeScrollButtonClicked' ? 'first-box-inner-mounted first-box-inner ' : 'first-box-inner'}>
          <div className="first-box-front">
          </div>
          <div className="first-box-back">
          </div>
        </div>
        <div className='first-box-text'>
          <div className='first-box-text-headline'>
            Fast
          </div>
          <div className='first-box-text-content'>
            Fast load times and lag free interaction, my highest priority.
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}
export default connect(mapStateToProps)(FirstElement);
