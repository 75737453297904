import '../../css/SomethingAboutMe.css';
import AboutListWrapper from './AboutListWrapper.js';
import ProfilePicSkillWrapper from './ProfilePicSkillWrapper.js';
import ReactDOM from 'react-dom';
import { somethingAboutMeYOffsetAction } from '../../actions/main';

import {connect} from 'react-redux';

import React from 'react';

class SomethingAboutMe extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      componentY: ''
    };
  }
  componentDidMount() {
    this.props.dispatch(somethingAboutMeYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.props.dispatch(somethingAboutMeYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  render(){
    const { showSomethingAboutMeParam } = this.props;
    return (
      <div ref={this.wrapper} style={{opacity:showSomethingAboutMeParam ? 1 : 0}} className="something-about-me-wrapper">
        <div className='something-about-us-header'>
          Something about me
        </div>
        <div className='something-about-us-content'>
        I am a Front-End Developer from Belgrade , Serbia.
        I have serious passion for animations and creating intuitive, dynamic user experiences.
        I have a lot of experience working solo on complete projects, from organizing, designing , to making all work fast,
        precise and dynamic.
        Lets make something special.
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const somethingAboutMeYOffsetParam = state.somethingAboutMeYOffsetParam;
  const showSomethingAboutMeParam = state.showSomethingAboutMeParam;

  return {
    somethingAboutMeYOffsetParam,
    showSomethingAboutMeParam
  }
}

export default connect(mapStateToProps)(SomethingAboutMe);
