import '../../css/AboutProfileSkillWrapper.css';
import LeftSideSkill from './LeftSideSkill.js';
import RightSideSkill from './RightSideSkill.js';
import ProfilePicture from './ProfilePicture.js';
import RightSideSmallerSkill from './RightSideSmallerSkill.js';
import { pictureAndSkillYOffsetAction } from '../../actions/main';
import {connect} from 'react-redux';


import React from 'react';

class ProfilePicSkillWrapper extends React.Component {
  constructor(props) {
   super(props);
   this.wrapper = React.createRef();
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight
   };
 }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   this.props.dispatch(pictureAndSkillYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 updateDimensions = () => {
   this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
   this.props.dispatch(pictureAndSkillYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 componentWillUnmount() {
   window.removeEventListener('resize', this.updateDimensions);
 }
  render(){
    const { showProfileAndSkillParam } = this.props;
    return (
      <div ref={this.wrapper} style={{opacity: showProfileAndSkillParam ? 1 : 0}} className='profile-pic-stats-wrapper'>
      {this.state.windowWidth > 1000 ?
        <>
        <LeftSideSkill/>
        <ProfilePicture/>
        <RightSideSkill/>
        </>
        :
        <>
        <ProfilePicture/>
        <LeftSideSkill/>
        <RightSideSmallerSkill/>
        </>
      }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const showProfileAndSkillParam = state.showProfileAndSkillParam;
  return {
    showProfileAndSkillParam
  }
}

export default connect(mapStateToProps)(ProfilePicSkillWrapper);
