import {
TEST_ACTION,
SCROLLEDSTATE_ACTION,
SHOWPROFILEANDSKILL_ACTION,
LOADARROWS_ACTION,
LOADSOMETHINGABOUTME_ACTION,
SHOWSOMETHINGABOUTME_ACTION,
SOMETHINGABOUTMEYOFFSET_ACTION,
PICTUREANDSKILLYOFFSET_ACTION,
PORTFOLIOYOFFSET_ACTION,
CONTACTYOFFSET_ACTION,
SHOWPORTFOLIO_ACTION
} from '../constants';

export function testAction ( testParam ) {
  return {
    type: TEST_ACTION,
    testParam
  }
}
export function scrolledStateAction ( scrolledStateParam ) {
  return {
    type: SCROLLEDSTATE_ACTION,
    scrolledStateParam
  }
}

export function showProfileAndSkillAction ( showProfileAndSkillParam ) {
  return {
    type: SHOWPROFILEANDSKILL_ACTION,
    showProfileAndSkillParam
  }
}
export function loadArrowsAction ( loadArrowsParam ) {
  return {
    type: LOADARROWS_ACTION,
    loadArrowsParam
  }
}
export function showSomethingAboutMeAction ( showSomethingAboutMeParam ) {
  return {
    type: SHOWSOMETHINGABOUTME_ACTION,
    showSomethingAboutMeParam
  }
}
export function loadSomethingAboutMeAction ( loadSomethingAboutMeParam ) {
  return {
    type: LOADSOMETHINGABOUTME_ACTION,
    loadSomethingAboutMeParam
  }
}
export function somethingAboutMeYOffsetAction ( somethingAboutMeYOffsetParam ) {
  return {
    type: SOMETHINGABOUTMEYOFFSET_ACTION,
    somethingAboutMeYOffsetParam
  }
}
export function pictureAndSkillYOffsetAction ( pictureAndSkillYOffsetParam ) {
  return {
    type: PICTUREANDSKILLYOFFSET_ACTION,
    pictureAndSkillYOffsetParam
  }
}
export function portfolioYOffsetAction ( portfolioYOffsetParam ) {
  return {
    type: PORTFOLIOYOFFSET_ACTION,
    portfolioYOffsetParam
  }
}
export function contactYOffsetAction ( contactYOffsetParam ) {
  return {
    type: CONTACTYOFFSET_ACTION,
    contactYOffsetParam
  }
}
export function showPortfolioAction ( showPortfolioParam ) {
  return {
    type: SHOWPORTFOLIO_ACTION,
    showPortfolioParam
  }
}
