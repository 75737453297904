import {connect} from 'react-redux';
import React from 'react';
import '../../css/Portfolio.css';
import beoguma from '../../video/beoguma.mp4';
import cleanCommerce from '../../video/cleanCommerce.mp4';
import rigWatcher from '../../video/rigWatcher.mp4';
import onlineDoctor from '../../video/onlineDoctor.mp4';
import { portfolioYOffsetAction } from '../../actions/main';
import PortfolioVideoGallery from './PortfolioVideoGallery';
import PortfolioPartnerLogos from './PortfolioPartnerLogos';


const looping = [beoguma , cleanCommerce , rigWatcher , onlineDoctor];
const videos =['beoguma' , 'cleanCommerce' , 'rigWatcher' , 'onlineDoctor']
const videoDescrition =[
  'Car service app for checking basically everything that you fixed or serviced on your vehicle...',
  'Private app for scheduling deep washing services for furniture, carpets or cars...',
  'Crypto mining app for checking stats of pool,getting notifications for changes...',
  'health based app for checking biochemical, hormonal, urogical and other results...']

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      videoHovered: [false , false, false , false],
      removeHoveredWrapper: [false , false , false , false]
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.dispatch(portfolioYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));

  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.props.dispatch(portfolioYOffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  turnOffOnMouseOut() {
    setTimeout(()=>{
      this.setState({videoHovered:[false,false,false,false]});
    },10)
  }
  render(){
    const { testParam , showPortfolioParam } = this.props;
    return (
      <div ref={this.wrapper} className='portfolio-wrapper'>
      <div style={{opacity: showPortfolioParam == true ? 1 : 0}} className='portfolio-inner-wrapper'>
        <div className='portfolio-header'>
          Portfolio
        </div>
        <PortfolioVideoGallery/>
        <PortfolioPartnerLogos/>
      </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  const showPortfolioParam = state.showPortfolioParam;
  return {
    testParam,
    showPortfolioParam
  }
}
export default connect(mapStateToProps)(Portfolio);
