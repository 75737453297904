import '../../css/Home.css';
import Navigation from './Navigation.js';
import Wallpaper from './Wallpaper.js';
import HelloMessage from './HelloMessage.js';


import React from 'react';

class Home extends React.Component {
  
  render() {
    return (
      <div className="Home-page-wrapper">
        <Navigation/>
        <Wallpaper/>
        <HelloMessage/>
      </div>
    );
  }
}

export default Home;
