import '../../css/About.css';
import AboutListWrapper from './AboutListWrapper.js';
import ProfilePicSkillWrapper from './ProfilePicSkillWrapper.js';
import SomethingAboutMe from './SomethingAboutMe.js';
import {connect} from 'react-redux';
import React from 'react';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  render(){
    const { testParam } = this.props;
    return (
      <div ref={this.wrapper} className="About-page-wrapper">
        <div className={testParam === 'homeScrollButtonClicked' ? 'About-header' : 'About-header-before'}>
          About
        </div>
        <AboutListWrapper/>
        <ProfilePicSkillWrapper/>
        <SomethingAboutMe/>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  return {
    testParam
  }
}

export default connect(mapStateToProps)(About);
