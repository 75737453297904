import {
  TEST_ACTION,
  SCROLLEDSTATE_ACTION,
  SHOWPROFILEANDSKILL_ACTION,
  LOADARROWS_ACTION,
  LOADSOMETHINGABOUTME_ACTION,
  SOMETHINGABOUTMEYOFFSET_ACTION,
  PICTUREANDSKILLYOFFSET_ACTION,
  SHOWSOMETHINGABOUTME_ACTION,
  SHOWPORTFOLIO_ACTION,
  PORTFOLIOYOFFSET_ACTION,
  CONTACTYOFFSET_ACTION
} from '../constants';

const initialState = {
  testParam:'Default Value',
  scrolledStateParam: 'home',
  showProfileAndSkillParam: false,
  loadArrowsParam : false,
  loadSomethingAboutMeParam : false,
  pictureAndSkillYOffsetParam: 0,
  somethingAboutMeYOffsetParam: 0,
  showSomethingAboutMeParam : false,
  showPortfolioParam: false,
  portfolioYOffsetParam: 0,
  contactYOffsetParam: 0,
  error: {
    message: 'Default Message',
    isShown: false,
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case TEST_ACTION:
      const testState = Object.assign({}, state);
      testState.testParam = action.testParam;
      return testState;
    case SCROLLEDSTATE_ACTION:
      const scrolledState = Object.assign({}, state);
      scrolledState.scrolledStateParam = action.scrolledStateParam;
      return scrolledState;
    case SHOWPROFILEANDSKILL_ACTION:
      const showProfileAndSkillState = Object.assign({}, state);
      showProfileAndSkillState.showProfileAndSkillParam = action.showProfileAndSkillParam;
      return showProfileAndSkillState;
    case LOADARROWS_ACTION:
      const loadArrowsState = Object.assign({}, state);
      loadArrowsState.loadArrowsParam = action.loadArrowsParam;
      return loadArrowsState;
    case LOADSOMETHINGABOUTME_ACTION:
      const loadSomethingAboutMeState = Object.assign({}, state);
      loadSomethingAboutMeState.loadSomethingAboutMeParam = action.loadSomethingAboutMeParam;
      return loadSomethingAboutMeState;
    case SOMETHINGABOUTMEYOFFSET_ACTION:
      const somethingAboutMeYOffsetState = Object.assign({}, state);
      somethingAboutMeYOffsetState.somethingAboutMeYOffsetParam = action.somethingAboutMeYOffsetParam;
      return somethingAboutMeYOffsetState;
    case PICTUREANDSKILLYOFFSET_ACTION:
      const pictureAndSkillYOffsetState = Object.assign({}, state);
      pictureAndSkillYOffsetState.pictureAndSkillYOffsetParam = action.pictureAndSkillYOffsetParam;
      return pictureAndSkillYOffsetState;
    case PORTFOLIOYOFFSET_ACTION:
      const portfolioYOffsetState = Object.assign({}, state);
      portfolioYOffsetState.portfolioYOffsetParam = action.portfolioYOffsetParam;
      return portfolioYOffsetState;
    case CONTACTYOFFSET_ACTION:
      const contactYOffsetState = Object.assign({}, state);
      contactYOffsetState.contactYOffsetParam = action.contactYOffsetParam;
      return contactYOffsetState;
    case SHOWSOMETHINGABOUTME_ACTION:
      const showSomethingAboutMeState = Object.assign({}, state);
      showSomethingAboutMeState.showSomethingAboutMeParam = action.showSomethingAboutMeParam;
      return showSomethingAboutMeState;
    case SHOWPORTFOLIO_ACTION:
      const showPortfolioState = Object.assign({}, state);
      showPortfolioState.showPortfolioParam = action.showPortfolioParam;
      return showPortfolioState;
    default:
      return state;
  }
}

export default reducer;
